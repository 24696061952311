'use strict';

angular.module('managerApp').config( ($routeProvider) => {
    $routeProvider.when('/ads', {
        templateUrl: 'app/ads/list.html',
        controller: 'AdsController',
        authenticate: 'user',
        hasPermissions: ['ADS:GET']
    });
});
